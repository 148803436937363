import React from "react";
import { useSelector } from "react-redux";
import CertificateItem from "./CertificateItem";
export default function Certification() {
  const { certifications } = useSelector((state) => state.resume.resume);
  return (
    <div className='education-section px-3 px-lg-4 pb-4'>
      <h2 className='h3 mb-4'>Certifications</h2>
      <div className='timeline'>
        {certifications.map((cer, i) => {
          return <CertificateItem key={i} {...cer} />;
        })}
      </div>
    </div>
  );
}
