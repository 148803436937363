import About from "./About/About";
import Contact from "./Contact/Contact";
import Education from "./Education/Education";
import Hero from "./Hero";
import Skill from "./Skill/Skill";
import Work from "./Work/Work";
import Footer from "./Layout/Footer";
import React from "react";
import Certification from "./Certification/Certification";

export default function Main() {
  return (
    <div className='page-content'>
      <div className='container'>
        <div className='cover shadow-lg bg-white'>
          <Hero />
          <About />
          <hr className='d-print-none' />
          <Skill />
          <hr className='d-print-none' />
          <Work />
          <hr className='d-print-none' />
          <Education />
          <hr className='d-print-none' />
          <Certification />
          <hr className='d-print-none' />
          <Contact />
          <Footer />
        </div>
      </div>
    </div>
  );
}
