import React from "react";
import getage from "get-age";
import { useSelector } from "react-redux";

export default function About() {
  const { resume } = useSelector((state) => state.resume);
  return (
    <div className='about-section pt-4 px-3 px-lg-4 mt-1'>
      <div className='row'>
        <div className='col-md-6'>
          <h2 className='h3 mb-3'>About Me</h2>
          <p>{resume.summary.description}</p>
        </div>
        <div className='col-md-5 offset-md-1'>
          <div className='row mt-2'>
            <div className='col-sm-4'>
              <div className='pb-1'>Age</div>
            </div>
            <div className='col-sm-8'>
              <div className='pb-1 text-secondary'>
                {getage(resume.summary.birthday)}
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='pb-1'>Email</div>
            </div>
            <div className='col-sm-8'>
              <div className='pb-1 text-secondary'>{resume.contact.email}</div>
            </div>
            <div className='col-sm-4'>
              <div className='pb-1'>Phone</div>
            </div>
            <div className='col-sm-8'>
              <div className='pb-1 text-secondary'>{resume.contact.mobile}</div>
            </div>
            <div className='col-sm-4'>
              <div className='pb-1'>Address</div>
            </div>
            <div className='col-sm-8'>
              <div className='pb-1 text-secondary'>
                {resume.contact.address}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
