import React from "react";
import { useSelector } from "react-redux";
import WorkItem from "./WorkItem";

export default function Work() {
  const { resume } = useSelector((state) => state.resume);
  const { workHistory } = resume;
  return (
    <div className='work-experience-section px-3 px-lg-4'>
      <h2 className='h3 mb-4'>Work Experience</h2>
      <div className='timeline'>
        {workHistory.map((work, i) => {
          return <WorkItem {...work} key={i} />;
        })}
      </div>
    </div>
  );
}
