import React from "react";

export default function CertificateItem({ name, issuedBy, issuedOn, url }) {
  return (
    <div className='timeline-card timeline-card-success card shadow-sm'>
      <div className='card-body'>
        <div className='h5 mb-1'>{name}</div>
        <span className='text-muted h6'>from {issuedBy}</span>
        <div className='text-muted text-small mb-2'>{issuedOn}</div>
        <div></div>
      </div>
    </div>
  );
}
