import React from "react";
import { useSelector } from "react-redux";

export default function Navigation() {
  const { social } = useSelector((state) => state.resume.resume);
  return (
    <nav role='navigation'>
      <ul className='nav justify-content-center'>
        {social.map((soc, i) => {
          return (
            <li className='nav-item' key={i}>
              <a className='nav-link' href={soc.url} title='Twitter'>
                <i className={soc.icon}></i>
                <span className='menu-title sr-only'>{soc.name}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
