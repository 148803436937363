import Header from "./components/Layout/Header";
import Main from "./components/Main";
import AOS from "aos";
import "aos/dist/aos.css";
function App() {
  AOS.init();
  return (
    <>
      <div id='top'>
        <Header />
        <Main />
      </div>
    </>
  );
}

export default App;
