import React from "react";
import { useSelector } from "react-redux";
import Navigation from "./Navigation";

export default function Footer() {
  const {
    summary: { firstName, lastName },
  } = useSelector((state) => state.resume.resume);
  return (
    <footer className='pt-4 pb-4 text-muted text-center d-print-none'>
      <div className='container'>
        <div className='my-3'>
          <div className='h4'>
            {lastName} {firstName}
          </div>
          <div className='footer-nav'>
            <Navigation />
          </div>
        </div>
        <div className='text-small'>
          <div className='mb-1'>&copy; Right Resume. All rights reserved.</div>
          <div>
            Design -{" "}
            <a
              href='https://templateflip.com/'
              target='_blank'
              rel='noreferrer'
            >
              TemplateFlip
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
