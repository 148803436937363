import React from "react";
import { useSelector } from "react-redux";

export default function Contact() {
  const { social, contact } = useSelector((state) => state.resume.resume);
  return (
    <div className='contant-section px-3 px-lg-4 pb-4' id='contact'>
      <h2 className='h3 text mb-3'>Contact</h2>
      <div className='row' style={{ justifyContent: "center" }}>
        <div className='col-md-8 d-print-none'>
          <div className='my-2'>
            <form action='https://formspree.io/your@email.com' method='POST'>
              <div className='row'>
                <div className='col-6'>
                  <input
                    className='form-control'
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Your Name'
                    required
                  />
                </div>
                <div className='col-6'>
                  <input
                    className='form-control'
                    type='email'
                    id='email'
                    name='_replyto'
                    placeholder='Your E-mail'
                    required
                  />
                </div>
              </div>
              <div className='form-group my-2'>
                <textarea
                  className='form-control'
                  style={{ resize: "none" }}
                  id='message'
                  name='message'
                  rows='4'
                  placeholder='Your Message'
                  required
                />
              </div>
              <button className='btn btn-primary mt-2' type='submit'>
                Send
              </button>
            </form>
          </div>
        </div>
        <div className='col d-none d-print-block'>
          <div className='mt-2'>
            <h3 className='h6'>Address</h3>
            <div className='pb-2 text-secondary'>{contact.address}</div>
            <h3 className='h6'>Phone</h3>
            <div className='pb-2 text-secondary'>{contact.mobile}</div>
            <h3 className='h6'>Email</h3>
            <div className='pb-2 text-secondary'>{contact.email}</div>
          </div>
        </div>
        <div className='col d-none d-print-block'>
          <div className='mt-2'>
            <div>
              {social.map((soc, i) => {
                return (
                  <div className='mb-2' key={i}>
                    <div className='text-dark'>
                      <i className={soc.icon + " mr-1"}></i>
                      <span>{soc.url}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
