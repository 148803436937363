import React from "react";

export default function SkillItem({ name, progress }) {
  return (
    <div className='mb-2'>
      <span>{name}</span>
      <div className='progress my-1'>
        <div
          className='progress-bar bg-primary'
          role='progressbar'
          data-aos='zoom-in-right'
          data-aos-delay='100'
          data-aos-anchor='.skills-section'
          style={{ width: progress + "%" }}
          aria-valuenow={progress}
          aria-valuemin='0'
          aria-valuemax='100'
        ></div>
      </div>
    </div>
  );
}
