import React from "react";
import { useSelector } from "react-redux";
import Navigation from "./Navigation";

export default function Header() {
  const { siteInfo } = useSelector((state) => state.resume);
  return (
    <header className='d-print-none'>
      <div className='container text-center text-lg-left'>
        <div className='py-3 clearfix'>
          <h1 className='site-title mb-0'>{siteInfo.logo.text}</h1>
          <div className='site-nav'>
            <Navigation />
          </div>
        </div>
      </div>
    </header>
  );
}
