import React from "react";
import { useSelector } from "react-redux";
import SkillItem from "./SkillItem";
export default function Skill() {
  const { resume } = useSelector((state) => state.resume);
  const { main, other } = resume.skills;
  return (
    <div className='skills-section px-3 px-lg-4'>
      <h2 className='h3 mb-3'>Professional Skills</h2>
      <div className='row'>
        {main.map((skill, i) => {
          return (
            <div className='col-md-6' key={i}>
              <SkillItem {...skill} />
            </div>
          );
        })}
      </div>
      <div className='row'>
        <div>
          <span className='text-muted small'>Others : {other.join(" , ")}</span>
        </div>
      </div>
    </div>
  );
}
