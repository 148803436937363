import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  resume: {
    resumeUrl: process.env.PUBLIC_URL + "/assets/hhresume.pdf",
    hireMeUrl: "mailto:hasan@hasanli.info",
    summary: {
      firstName: "Hasan",
      lastName: "Hasanli",
      description: "Hello! I’m Hasanli Hasan",
      profession: "Software Developer",
      birthday: "1993-09-11",
      photoUrl: "/assets/images/circle.png",
    },
    contact: {
      email: "hasan@hasanli.info",
      mobile: "+994(55) 684-74-04",
      phone: "",
      address: "Baku,Azerbaijan",
    },
    educations: [
      {
        name: "IT Academy",
        specialty: "Web Development",
        started: "2022-09",
        ended: "2023-02",
        location: "Baku,Azerbaijan",
        description: "",
      },
      {
        name: "Baku Business Univercity",
        specialty: "Management",
        started: "2012-09",
        ended: "2016-07",
        location: "Baku,Azerbaijan",
        description: "",
      },
    ],
    workHistory: [
      {
        company: "KontaktHome",
        location: "Baku,Azerbaijan",
        position: "Software Developer",
        start: "Nov, 2020",
        end: "Dec, 2022",
        details: [
          "Development of new ERP system (C#, .NET).",
          "Established integration with external services (Two-Way SMS, e-Government System, e-Payment and others)",
          "Established integration of external devices with system. (POS Devices,TAX Devices)",
          "Database preparation, wrote SQL queries. (MSSQL)",
        ],
      },
      {
        company: "KontaktHome GE",
        location: "Tbilisi,Georgia",
        position: "Software Developer",
        start: "Nov, 2021",
        end: "Dec, 2021",
        details: [
          "Addition of new functionality on ERP (C#, .NET).",
          "Database improvement, wrote SQL queries. (MSSQL)",
        ],
      },
      {
        company: "KontaktHome",
        location: "Baku,Azerbaijan",
        position: "1C Programmer",
        start: "Feb, 2019",
        end: "Nov, 2020",
        details: [
          "Software control, addition of new functionality,improvement of existing functionality",
        ],
      },
    ],
    certifications: [
      {
        issuedBy: "ABTC",
        name: "Business organization and staff management",
        issuedOn: "2018-08",
        url: "",
      },
      {
        issuedBy: "BilgeAdam Technologies",
        name: "Programming in HTML5 with JavaScript and CSS3",
        issuedOn: "2021-11",
        url: "",
      },
      {
        issuedBy: "BilgeAdam Technologies",
        name: "Developing ASP.NET MVC Web Applications",
        issuedOn: "2021-11",
        url: "",
      },
      {
        issuedBy: "IT-Academy",
        name: "Web Development ",
        issuedOn: "2023-02",
        url: "",
      },
    ],
    social: [
      {
        name: "GitHub",
        icon: "fab fa-github",
        url: "https://github.com/hasanlee",
      },
      {
        name: "LinkedId",
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/hasanli-hasan/",
      },
    ],
    languages: [
      {
        name: "English",
        level: 3,
      },
      {
        name: "Turkish",
        level: 4,
      },
      {
        name: "Russian",
        level: 2,
      },
    ],
    skills: {
      main: [
        {
          name: "C#,.NET Core,.NET Framework",
          progress: 80,
        },
        {
          name: "ReactJS,Redux Toolkit",
          progress: 95,
        },
        {
          name: "HTML5,CSS,JavaScript",
          progress: 95,
        },
        {
          name: "PHP,Laravel",
          progress: 85,
        },
        {
          name: "NodeJS,ExpressJs",
          progress: 94,
        },
        {
          name: "MS SQL,MySQL,MongoDB",
          progress: 87,
        },
      ],
      other: [
        "MVC",
        "Bootstrap",
        "TailwindCSS",
        "Sass",
        "REST",
        "SOAP",
        "Postman",
        "Git",
        "GitHub",
        "Docker",
        "ERP",
      ],
    },
  },
  siteInfo: {
    logo: {
      url: "",
      text: "./hasanli.info",
    },
  },
};

const resume = createSlice({
  name: "resume",
  initialState,
  reducers: {
    postData: () => {
      console.log("post data");
    },
  },
  extraReducers: {},
});

export const { postData } = resume.actions;
export default resume.reducer;
