import React from "react";

export default function EducationItem({
  name,
  specialty,
  started,
  ended,
  location,
  description,
}) {
  return (
    <div className='timeline-card timeline-card-success card shadow-sm'>
      <div className='card-body'>
        <div className='h5 mb-1'>
          {specialty} <span className='text-muted h6'>from {name}</span>
        </div>
        <div className='text-muted text-small mb-2'>
          {started} - {ended}{" "}
          <span className='text-muted small'>{location}</span>
        </div>
        <div>{description}</div>
      </div>
    </div>
  );
}
