import React from "react";
import { useSelector } from "react-redux";
import EducationItem from "./EducationItem";
export default function Education() {
  const { resume } = useSelector((state) => state.resume);
  const { educations } = resume;
  return (
    <div className='education-section px-3 px-lg-4 pb-4'>
      <h2 className='h3 mb-4'>Education</h2>
      <div className='timeline'>
        {educations.map((education, i) => {
          return <EducationItem {...education} key={i} />;
        })}
      </div>
    </div>
  );
}
