import React from "react";
import { useSelector } from "react-redux";

export default function Hero() {
  const { resumeUrl, hireMeUrl, summary } = useSelector(
    (state) => state.resume.resume
  );
  return (
    <div className='cover-bg p-3 p-lg-4 text-white'>
      <div className='row'>
        <div className='col-lg-4 col-md-5'>
          <div
            className='avatar hover-effect shadow-sm p-1'
            style={{ borderRadius: "50%" }}
          >
            <img
              src={summary.photoUrl}
              width='200'
              height='200'
              alt='logo'
              style={{ borderRadius: "50%" }}
            />
          </div>
        </div>
        <div className='col-lg-8 col-md-7 text-center text-md-start'>
          <h2 className='h1 mt-2' data-aos='fade-left' data-aos-delay='0'>
            {summary.firstName} {summary.lastName}
          </h2>
          <p data-aos='fade-left' data-aos-delay='100'>
            {summary.profession}
          </p>
          <div
            className='d-print-none'
            data-aos='fade-left'
            data-aos-delay='200'
          >
            <a
              className='btn btn-light text-dark shadow-sm mt-1 me-1'
              href={resumeUrl}
              target='_blank'
              rel='noreferrer'
            >
              Download CV
            </a>
            <a
              className='btn btn-success shadow-sm mt-1'
              href={hireMeUrl}
              target='_blank'
              rel='noreferrer'
            >
              Hire Me
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
