import React from "react";

export default function WorkItem({
  company,
  location,
  position,
  start,
  end,
  details,
}) {
  return (
    <div className='timeline-card timeline-card-primary card shadow-sm'>
      <div className='card-body'>
        <div className='h5 mb-1'>
          {position} <span className='text-muted h6'>at {company}</span>
        </div>
        <div className='text-muted text-small mb-2'>
          {start} - {end} <span className='text-muted small'>{location}</span>
        </div>
        <div>
          <ul>
            {details.map((row, i) => {
              return <li key={i}>{row}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
